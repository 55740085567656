/** @jsx jsx */
import {
  jsx,
  Flex,
  Box,
  Container,
  Themed,
  Input,
  Button,
  Label,
} from "theme-ui";
import React, { useContext, useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import { LocalizationContext } from "../../context/LocalizationContext";
import Table from "../../components/Table";
import Form from "../../components/Form";
import * as api from "../../utils/api";

const DanskeAdminPage = () => {
  const { language, translate, setLanguage } = useContext(LocalizationContext);

  let formData = {
    type: "danskeAdminLogin",
    customApiSubmit: "sendDanskeAdminLogin",
    disableShowNotification: true,
    button: "Kirjaudu",
    customErrorMsg: "usrOrPswNotValid",

    formFields: [
      {
        id: "usr",
        label: "Käyttäjätunnus",
        type: "text",
        required: true,
      },
      {
        id: "psw",
        label: "Salasana",
        type: "password",
        required: true,
      },
    ],
  };

  let td = {
    headers: [
      "Id",
      "Aika",
      "b-id",
      "Fennia | Danske asiakas",
      "Kuuli eduista",
    ],
    footer: "Rivit yhteensä:",

    filterOptions: [
      {
        val: 1,
        txt: "1 kk.",
      },
      {
        val: 3,
        txt: "3 kk.",
      },
      {
        val: 6,
        txt: "6 kk.",
        selected: "selected",
      },
      {
        val: 12,
        txt: "12 kk.",
      },
    ],
    rows: [],
    filterButton: {
      txt: "Excel csv",
    },
    rowsTot: 0,
  };

  if (language === "sv") {
    formData = {
      type: "danskeAdminLogin",
      customApiSubmit: "sendDanskeAdminLogin",
      disableShowNotification: true,
      button: "Logga in",
      customErrorMsg: "usrOrPswNotValid",

      formFields: [
        {
          id: "usr",
          label: "Användarnamn",
          type: "text",
          required: true,
        },
        {
          id: "psw",
          label: "Lösenord",
          type: "password",
          required: true,
        },
      ],
    };

    td = {
      headers: [
        "Id",
        "Tid",
        "b-id",
        "Fennia | Danske kund",
        "Hörde om förmånerna",
      ],
      footer: "Rader totalt:",

      filterOptions: [
        {
          val: 1,
          txt: "1 mån.",
        },
        {
          val: 3,
          txt: "3 mån.",
        },
        {
          val: 6,
          txt: "6 mån.",
          selected: "selected",
        },
        {
          val: 12,
          txt: "12 mån.",
        },
      ],
      rows: [],
      filterButton: {
        txt: "Excel csv",
      },
      rowsTot: 0,
    };
  }

  const [leadData, setLeadData] = useState(() => {
    return td;
  });
  const [leadDataFilter, setLeadDataFilter] = useState({ months: 6 });
  const [activeForm, setAciveForm] = useState(() => {
    return "danskeCustomerContact";
  });
  const [authToken, setAuthToken] = useState();

  const [isSelectedInner, setIsSelectedInner] = useState(() => {
    return true;
  });
  const [isSelectedOuter, setIsSelectedOuter] = useState(() => {
    return false;
  });

  useEffect(() => {
    const userPreferredLanguage = localStorage.getItem("userPreferredLanguage");
    if (userPreferredLanguage && userPreferredLanguage !== language) {
      setLanguage(userPreferredLanguage);
    }
  });

  useEffect(() => {
    if (authToken !== "") getLeadData(activeForm);
  }, [authToken, leadDataFilter]);

  const customApiSubmitResponce = async (respData) => {
    if (respData.status == 200 && respData.headers["x-auth-token"]) {
      setAuthToken(respData.headers["x-auth-token"]);
    }
  };

  const onFilterSelectChange = (e) => {
    setLeadDataFilter(() => {
      return { months: parseInt(e.target.value) };
    });
  };

  const onFilterButtonClick = (e) => {
    if (leadData.rowsTot != 0)
      setLeadDataFilter(() => {
        return { months: leadDataFilter.months, format: "csv" };
      });
  };

  const getLeadData = async (formType) => {
    setAciveForm(formType);
    if (formType == "danskeCustomerContact") {
      setIsSelectedInner(true);
      setIsSelectedOuter(false);
    } else {
      setIsSelectedInner(false);
      setIsSelectedOuter(true);
    }

    const response = await api.getDanskeAdminLeadData(
      authToken,
      formType,
      leadDataFilter
    );

    if (leadDataFilter.format == "csv") {
      setLeadDataFilter({ months: leadDataFilter.months });
    } else {
      // populate empty row to trigger Table component generation
      if (response.data.Items.length === 0) {
        leadData.rows = [["", "", "", "", "", "", ""]];
        leadData.rowsTot = 0;
        leadData.footer = td.footer + " 0";
      } else {
        let data = [];
        let o = [];
        response.data.Items.forEach((row) => {
          data.push([
            row.id,
            row.dateFormated +" "+ row.timeFormated,
            row.bId,
            row.fenniaClient +" | "+ row.danskeClient,
            row.benefitSource,
          ]);
        });
        leadData.headers = td.headers;
        leadData.rows = data;
        leadData.rowsTot = data.length;
        leadData.footer = td.footer + " " + leadData.rowsTot;
      }
      setLeadData({ ...leadData });
    }
  };

  return (
    <Layout>
      <SEO
        title={
          language === "sv" ? "Dankebank Administration" : "Danskebank Hallinta"
        }
        hidden={true}
      />
      <Box
        sx={{ position: "relative", bg: "muted", flex: 1, overflow: "hidden" }}
      >
        <Container sx={{ position: "relative", py: [2, 3], zIndex: 1 }}>
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: [160, 7],
              right: 4,
              zIndex: 0,
              maxWidth: "60vw",
              opacity: 0.25,
            }}
          ></Box>
        </Container>
        <Container variant="narrow" sx={{ position: "relative", zIndex: 2 }}>
          <PageTitle>
            {language === "sv"
              ? "Danskebank Administration"
              : "Danskebank Hallinta"}
          </PageTitle>

          {(() => {
            if (authToken !== "" && leadData.rows.length > 0) {
              if (language === "sv") {
                return (
                  <>
                    <Themed.p>
                      <a
                        href="/danske-bankin-laheta-yhteydenottopyynto"
                        target="_blank"
                      >
                        Länk till interna formuläret
                      </a>{" "}
                      |{" "}
                      <a
                        href="/danske-bankin-asiakkaan-yhteydenottopyynto"
                        target="_blank"
                      >
                        Länk till exterana formuläret
                      </a>
                      <br />
                      <br />
                      Välj önskad gransknings intervall från drop-down listan.
                      <br />
                      Tryck på Excel CSV knappen för att generera en Excel fil
                      av vyn för vidare analysering.
                    </Themed.p>

                    <Button
                      sx={{
                        variant: isSelectedInner
                          ? "buttons.primary"
                          : "buttons.secondary",
                      }}
                      onClick={() => getLeadData("danskeCustomerContact")}
                    >
                      Interna formuläret
                    </Button>
                    <Button
                      sx={{
                        variant: isSelectedOuter
                          ? "buttons.primary"
                          : "buttons.secondary",
                      }}
                      onClick={() => getLeadData("danskeContact")}
                    >
                      Externa formuläret
                    </Button>
                    <Table
                      mb={5}
                      data={leadData}
                      onFilterSelectChange={onFilterSelectChange}
                      onFilterButtonClick={onFilterButtonClick}
                    ></Table>
                  </>
                );
              } else {
                return (
                  <>
                    <Themed.p>
                      <a
                        href="/danske-bankin-laheta-yhteydenottopyynto"
                        target="_blank"
                      >
                        Linkki sisäiseen lomakkeeseen
                      </a>{" "}
                      |{" "}
                      <a
                        href="/danske-bankin-asiakkaan-yhteydenottopyynto"
                        target="_blank"
                      >
                        Linkki ulkoiseen lomakkeeseen
                      </a>
                      <br />
                      <br />
                      Valitse tarkasteltava aikaväli pudotusvalikosta.
                      <br />
                      Excel CSV painikkeesta saat tulostettua näkymän myös
                      Exceliin jatkoanalysointia varten.
                    </Themed.p>

                    <Button
                      sx={{
                        variant: isSelectedInner
                          ? "buttons.primary"
                          : "buttons.secondary",
                      }}
                      onClick={() => getLeadData("danskeCustomerContact")}
                    >
                      Sisäinen lomake
                    </Button>
                    <Button
                      sx={{
                        variant: isSelectedOuter
                          ? "buttons.primary"
                          : "buttons.secondary",
                      }}
                      onClick={() => getLeadData("danskeContact")}
                    >
                      Ulkoinen lomake
                    </Button>
                    <Table
                      mb={5}
                      data={leadData}
                      onFilterSelectChange={onFilterSelectChange}
                      onFilterButtonClick={onFilterButtonClick}
                    ></Table>
                  </>
                );
              }
            } else {
              return (
                <Form
                  data={formData}
                  customApiSubmitResponce={customApiSubmitResponce}
                />
              );
            }
          })()}
        </Container>
      </Box>
    </Layout>
  );
};

export default DanskeAdminPage;
